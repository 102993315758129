import React from 'react'
import styled from 'styled-components'

import { Content, Heading } from 'components/shared/Typography'
import LazyImage from 'components/shared/LazyImage'
import Container from 'components/shared/Container'
import { Col, Row } from 'components/shared/Grid'

import type Image from 'types/image'

type Props = {
  heading: string
  description: string
  image: Image
}

const Section = styled.section`
  position: relative;
  padding: 4rem 0;
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 15vw;
    background: ${({ theme }) => theme.colors.primary};
    ${({ theme }) => theme.media.md.max} {
      bottom: 1rem;
      top: auto;
      height: calc(300px + 1rem);
      width: 100vw;
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 85vw;
    height: calc(100% - 4rem);
    background: ${({ theme }) => theme.colors.white50};
    ${({ theme }) => theme.media.md.max} {
      width: 100vw;
      height: calc(100% - 300px - 2rem);
    }
  }
  ${({ theme }) => theme.media.md.max} {
    padding: 2rem 0;
  }
`

const ContentWrapper = styled.article`
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  min-height: 700px;
  & > ${Row} {
    width: 100%;
    margin: 0;
    & > ${Col} {
      padding: 0;
    }
  }
`

const ImageWrapper = styled.div`
  width: calc(100% + 2rem);
  height: 300px;
  margin-left: -1.5rem;
  ${({ theme }) => theme.media.md.min} {
    position: absolute;
    top: 0;
    right: 0;
    left: 50%;
    bottom: 0;
    width: auto;
    height: auto;
    margin: 0;
  }
`

const ContentInner = styled.div`
  max-width: 670px;
  padding: 2rem 1.5rem 2rem 0;
  p:first-child {
    font-weight: 500;
    font-size: 1.1em;
  }
  ${({ theme }) => theme.media.md.max} {
    padding: 2rem 0;
  }
`

const InvestmentAbout: React.FC<Props> = ({ heading, description, image }) => {
  return (
    <Section id="about">
      <Container slim>
        <ContentWrapper>
          <Row alignItems="center">
            <Col xs={24} md={12}>
              <ContentInner>
                <Heading
                  as="h2"
                  dangerouslySetInnerHTML={{ __html: heading }}
                  size={50}
                  weight={500}
                />
                <Content dangerouslySetInnerHTML={{ __html: description }} />
              </ContentInner>
            </Col>
            <Col xs={24} md={12}>
              <ImageWrapper>
                <LazyImage src={image.src} alt={image.alt ?? ''} fill />
              </ImageWrapper>
            </Col>
          </Row>
        </ContentWrapper>
      </Container>
    </Section>
  )
}

export default InvestmentAbout
