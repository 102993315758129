import React, { useMemo } from 'react'
import { PageProps, graphql } from 'gatsby'

import Layout from 'components/layout/Layout'
import SEO from 'components/layout/SEO'

import Header, { HeaderSlide } from 'components/layout/Headers/WithSlides'
import InvestmentAbout from 'components/layout/Investment/About/Secondary'
import InvestmentGallery from 'components/layout/Investment/Gallery'
import InvestmentAdvantages from 'components/layout/Investment/Advantages'
import InvestmentLocation from 'components/layout/Investment/Location'
import Breadcrumbs, { Crumb } from 'components/layout/Breadcrumbs'
import Map from 'components/layout/Maps'

import type { SimpleInvestmentTemplateQuery } from 'types/graphql'
import type Image from 'types/image'
import type { AdvantageCard } from 'components/layout/Investment/Advantages/Card'
import type { Stat } from 'components/layout/Sections/Homepage/Statistics/SingleStat'
import type { Marker } from 'components/layout/Maps/Legend'

const INVESTMENT_CATEGORIES: { [x: string]: Crumb } = {
  'inwestycja-zakonczona': {
    name: 'Inwestycje zrealizowane',
    to: '/inwestycje-zrealizowane',
  },
  'inwestycja-zaplanowana': {
    name: 'Inwestycje w przygotowaniu',
    to: '/inwestycje-w-przygotowaniu',
  },
}

const SimpleInvestmentTemplate: React.FC<
  PageProps<SimpleInvestmentTemplateQuery>
> = ({ data }) => {
  const INVESTMENT = data.investment?.investmentFields
  const INVESTMENT_SEO = data.investment?.seo

  const categorySlug = data.investment?.investmentCategories?.nodes![0]!.slug!
  const CATEGORY = INVESTMENT_CATEGORIES[categorySlug]

  if (!INVESTMENT || !INVESTMENT_SEO)
    throw new Error("CMS data didn't load properly")

  const HEADER_SLIDES: HeaderSlide[] = useMemo(
    () =>
      INVESTMENT.investmentS1Slider?.map((slide) => ({
        image: {
          src: slide?.investmentS1SliderElementImg?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: slide?.investmentS1SliderElementImg?.altText!,
        },
        title: INVESTMENT.investmentS1Title!,
      })) || [],
    []
  )

  const GALLERY_IMAGES: Image[] = useMemo(
    () =>
      INVESTMENT.investmentS5Slider?.map((slide) => ({
        src: slide?.investmentS5SliderElementImg?.localFile?.childImageSharp
          ?.gatsbyImageData!,
        alt: slide?.investmentS5SliderElementImg?.altText!,
      })) || [],
    []
  )

  const ADVANTAGES_CARDS: AdvantageCard[] = useMemo(
    () =>
      INVESTMENT.investmentS6Slider?.map((slide) => ({
        heading: slide?.investmentS6SliderElementTitle!,
        description: slide?.investmentS6SliderElementDescription!,
        image: {
          src: slide?.investmentS6SliderElementImg?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: slide?.investmentS6SliderElementImg?.altText!,
        },
      })) || [],
    []
  )

  const LOCATION_ITEMS: Stat[] = useMemo(
    () =>
      INVESTMENT.investmentS7List?.map((stat) => ({
        icon: stat?.investmentS7ListElementIcon!,
        value: stat?.investmentS7ListElementTitle!,
        label: stat?.investmentS7ListElementDescription!,
      })) || [],
    []
  )

  const MAP_MARKERS: Marker[] = useMemo(
    () =>
      INVESTMENT.investmentMap?.investmentMapMarkers?.map(
        (marker) =>
          ({
            type: marker?.investmentMapMarkersCategory,
            lat: Number(marker?.investmentMapMarkersLat),
            lng: Number(marker?.investmentMapMarkersLng),
          } as Marker)
      ) || [],
    []
  )

  return (
    <Layout>
      <SEO seo={INVESTMENT_SEO} />
      <Breadcrumbs crumbs={[CATEGORY, { name: data.investment?.title! }]} />
      <Header
        fixedTitle
        slides={HEADER_SLIDES}
        scrollText="Zjedź niżej"
        scrollElement="#about"
      />
      <main>
        <InvestmentAbout
          heading={INVESTMENT.investmentS4Title!}
          image={{
            src: INVESTMENT.investmentS4List![0]!.investmentS4ListElementImg
              ?.localFile?.childImageSharp?.gatsbyImageData!,
            alt: INVESTMENT.investmentS4List![0]!.investmentS4ListElementImg
              ?.altText!,
          }}
          description={
            INVESTMENT.investmentS4Description! +
            INVESTMENT.investmentS4List![0]!.investmentS4ListElementDescription!
          }
        />
        <InvestmentGallery
          title={INVESTMENT.investmentS5Title!}
          images={GALLERY_IMAGES}
        />
        <InvestmentAdvantages
          title={INVESTMENT.investmentS6Title!}
          list={ADVANTAGES_CARDS}
        />
        <InvestmentLocation
          heading={INVESTMENT.investmentS7Title!}
          description={INVESTMENT.investmentS7Description!}
          image={{
            src: INVESTMENT.investmentS7Img?.localFile?.childImageSharp
              ?.gatsbyImageData!,
            alt: INVESTMENT.investmentS7Img?.altText!,
          }}
          list={LOCATION_ITEMS}
        />
        <Map
          center={{
            lat: Number(INVESTMENT.investmentMap?.investmentMapLat),
            lng: Number(INVESTMENT.investmentMap?.investmentMapLng),
          }}
          zoom={15}
          investments={[
            {
              name: data.investment?.title!,
              image:
                INVESTMENT.investmentMapImg!.localFile!.childImageSharp!
                  .gatsbyImageData!,
              lat: Number(INVESTMENT.investmentMap?.investmentMapLat),
              lng: Number(INVESTMENT.investmentMap?.investmentMapLng),
            },
          ]}
          markers={MAP_MARKERS}
        />
      </main>
    </Layout>
  )
}

export default SimpleInvestmentTemplate

export const query = graphql`
  query SimpleInvestmentTemplate($slug: String) {
    investment: wpInvestment(slug: { eq: $slug }) {
      seo {
        ...WpSEO
      }
      title
      investmentCategories {
        nodes {
          slug
        }
      }
      investmentFields {
        investmentS1Title
        investmentS1Slider {
          investmentS1SliderElementImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 2000
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
        investmentS4Title
        investmentS4Description
        investmentS4List {
          investmentS4ListElementDescription
          investmentS4ListElementImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 900
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
        investmentS5Title
        investmentS5Slider {
          investmentS5SliderElementImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  height: 800
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
        investmentS6Title
        investmentS6Slider {
          investmentS6SliderElementTitle
          investmentS6SliderElementDescription
          investmentS6SliderElementImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  height: 400
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
        investmentS7Title
        investmentS7Description
        investmentS7Img {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                height: 600
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        investmentS7List {
          investmentS7ListElementTitle
          investmentS7ListElementDescription
          investmentS7ListElementIcon
        }
        investmentMap {
          investmentMapLng
          investmentMapLat
          investmentMapBoxDirection
          investmentMapMarkers {
            investmentMapMarkersCategory
            investmentMapMarkersLat
            investmentMapMarkersLng
          }
        }
        investmentMapImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 400
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  }
`
